import React from 'react';
import Img from 'gatsby-image';
import { AssetFile } from '../util';

function renderImage(file, props) {
  return (
    <>
      <Img
        className={props.imgClassName}
        fluid={file.childImageSharp.fluid}
        alt={props.imgAlt}
      />
      {props.imgCaption && (
        <p
          className="block text-xs mx-auto my-2.5 text-center"
          dangerouslySetInnerHTML={{ __html: props.imgCaption }}
        />
      )}
    </>
  )
}

const AssetImage = function (props) {
  const assetFile = AssetFile(props.imgPath);
  if (assetFile == null) return "";

  return renderImage(assetFile, props);
}

export default AssetImage;